input,
textarea {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid transparent;
    background-color: transparent;
    resize: none;
    max-height: none;
    overflow: hidden;
    overflow-wrap: break-word;
    height: auto;
    margin-top: 5px;
    width: -webkit-fill-available
}

input:focus {
    border: 1px solid #4251a5;
    outline: none;
}

textarea:focus {
    border: 1px solid #4251a5;
    outline: none;
}

input::file-selector-button {
    cursor: pointer;
    height: 100px;
    width: 108px;
    z-index: 1;
    background-color: transparent;
    border: none;
    color: transparent;
}