.main {
    display: flex;
}

.images {
    position: relative;
}

.title h2::after {
    content: "";
    background-color: #5d5dba;
    width: 64px;
    display: flex;
    height: 4px;
    margin-top: 6px;
}

.designation h3 {
    color: #5d5dba;
}

.details h2::after {
    content: "";
    background-color: #5d5dba;
    width: 64px;
    display: flex;
    height: 4px;
    margin-top: 8px;
    margin-bottom: 10px;
}

.designation {
    margin-left: 1em;
    height: fit-content;
    border: 1px solid #a1a5b7;
    border-radius: 4px;
    background: white;
    padding: 12px;
}

.designation .price table {
    background-color: white;
}

.designation table td {
    color: #222;
    padding-bottom: 15px;
    vertical-align: top;
    word-break: keep-all;
    word-wrap: break-word;
}

.designation table th {
    color: #888;
    padding-bottom: 15px;
    padding-right: 20px;
    vertical-align: top;
}

.designation table {
    table-layout: fixed;
    word-break: break-word;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
}

.description p {
    font-size: 14px !important;
    line-height: 23.8px;
    color: rgba(0, 0, 0, 0.7);
    text-align: justify;
}

.bigImage img {
    width: 100%;
    border-radius: 5px;
}

.smallImages {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.smallImages img {
    width: 20%;
    border-radius: 5px;
    cursor: pointer;
}

.smallImages img:hover {
    filter: blur(1px);
    border: 2px solid var(--Orange);
}

.infos {
    width: 50%;
    padding: 2%;
    margin-top: 50px;
}

.infos h3 {
    font-weight: 100;
    color: var(--Orange);
    font-size: 16px;
}

.infos h2 {
    font-size: 35px;
    margin-top: 15px;
}

.infos p {
    color: var(--Grayish-Blue);
    margin-top: 30px;
}

.price {
    margin-top: 15px;
}

.mainPrice {
    display: flex;
    gap: 8px;
    align-items: center;
}

.mainPriceSold {
    font-weight: 700;
    font-size: 25px;
}

.mainPriceDes {
    background-color: var(--Pale-orange);
    color: var(--Orange);
    padding: 2px 4px;
    border-radius: 4px;
}

.subPrice {
    margin-top: 10px;
    color: var(--Grayish-Blue);
    text-decoration: line-through;
}

.actions {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 10px;
}

.actionsInput {
    width: 100px;
    background-color: var(--Very-light-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 7px;
    border-radius: 5px;
}

.actionsInput button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.addToCartBtn {
    background: var(--Orange);
    border: none;
    height: 39px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    gap: 10px;
}

.addToCartBtn:hover {
    cursor: pointer;
    box-shadow: 0px 2px 30px -9px var(--Orange);
    opacity: 0.7;
}

.addToCartBtn img {
    color: #fff;
    filter: brightness(0) invert(1);
}


/* next and previous image style start */

.IconContainerNextImage,
.IconContainerPreviousImage {
    background-color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    cursor: pointer;
}

.IconContainerNextImage {
    left: 90%;
}

.IconContainerPreviousImage {
    left: 5%;
}

.closeIcon {
    position: absolute;
    top: -8px;
    left: 97%;
    filter: brightness(0) invert(1);
    width: 25px;
    cursor: pointer;
}


/* media screen style start */

@media only screen and (max-width: 721px) {
    .main {
        flex-direction: column;
    }
    .images {
        width: 100%;
    }
    .infos {
        width: 100%;
    }
    .actions {
        flex-direction: column;
    }
    .actionsInput {
        width: 100%;
    }
    .addToCartBtn {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .smallImages {
        display: none;
    }
}


/* media screen style end */