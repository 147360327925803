@media screen and (max-width: 1200px) {
    .logomachinery {
        height: 13%;
        width: 86%;
    }
}

@media screen and (max-width:991px) {
    .logomachiney {
        height: 3% !important;
        width: 44% !important;
    }
}

@media screen and (max-width: 768px) {
    .logomachinery {
        height: 3% !important;
        width: 33% !important;
    }
}